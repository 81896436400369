import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from '../../util_components/common_header';
import CustomInput from '../../util_components/custom_input';
import CustomModal from '../../util_components/custom_modal';
import Button from '../../util_components/button';
import CustomSingleSelect from '../../util_components/custom_single_select';
import SearchStudent from './Components/SearchStudent';
import SearchTeacher from './Components/SearchTeacher';
import ImageUploader from './Components/ImageUploader';
import { api_with_method } from '../../../redux/api_funcs';
import { get_api_url } from '../../../utils/urls';
import S3Handler from '../../../../myt_chat/src/chat/network/S3Handler';
import { S3ACL } from '../../../../myt_chat/src/chat/enums/S3ACL';

interface Member {
  avatar: string;
  group_role: string;
  role: string;
  uuid: string;
  name: string;
  fname?: string;
  lname?: string;
}

interface Group {
  uuid: number;
  name: string;
  avatar: string;
  type: string;
  create_mode: number;
  created_at: number;
  file?: File;
}

const datesForSearch = [
  { value: 'TODAY', label: 'TODAY' },
  { value: 'YESTERDAY', label: 'YESTERDAY' },
  { value: 'THIS WEEK', label: 'THIS WEEK' },
  { value: 'LAST WEEK', label: 'LAST WEEK' },
  { value: 'LAST 2 WEEKS', label: 'LAST 2 WEEKS' },
  { value: 'LAST 4 WEEKS', label: 'LAST 4 WEEKS' },
  { value: 'THIS MONTH', label: 'THIS MONTH' },
  { value: 'LAST MONTH', label: 'LAST MONTH' },
  { value: 'LAST 30 DAYS', label: 'LAST 30 DAYS' },
  { value: 'Anytime', label: 'Anytime' }
];

const GroupChats: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [manageOpen, setManageOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [dateFilterType, setDateFilterType] = useState('TODAY');
  const [allGroups, setAllGroups] = useState<Group[]>([]);
  const [addType, setAddType] = useState<string | null>(null);
  const [createGroup, setCreateGroup] = useState({
    name: '',
    description: '',
    avatar:
      'https://cloudimages.myyogateacher.com/dgerdfai4/image/upload/v1700811519/website/default-group-avatar.png',
    type: 'public',
    members: [],
    file: null
  });
  const [memberToAdd, setMemberToAdd] = useState<Member | undefined>();
  const [manageGroup, setManageGroup] = useState<
    | {
        group: Group;
        members: Array<Member>;
      }
    | undefined
  >();

  const studentSearchStatus = useSelector((state: any) => state.loading.student_search_status);
  const ianaTimezone = useSelector((state: any) => state.home.iana_timezone);

  useEffect(() => {
    getGroups();
  }, []);
  const createGroupNew = async () => {
    const url = get_api_url(`/v1.0/groups`, false, true);
    let payload = structuredClone(createGroup);
    payload.name = payload.name.trim();
    delete payload.members;
    delete payload.file;
    let response = await api_with_method('post', url, payload, true);
    let group_uuid = response.data.group;

    for (let i = 0; i < createGroup.members.length; i++) {
      const addUrl = `/v1.0/groups/${group_uuid}/members/${createGroup.members[i].uuid}/join`;
      const url = get_api_url(addUrl, false, true);
      await api_with_method('post', url, {}, true);
    }
    setCreateOpen(false);
    setCreateGroup({
      name: '',
      description: '',
      avatar: '',
      type: 'public',
      members: [],
      file: null
    });
    setLoading(false);
  };
  useEffect(() => {
    if (
      createGroup.avatar !==
      'https://cloudimages.myyogateacher.com/dgerdfai4/image/upload/v1700811519/website/default-group-avatar.png'
    ) {
      createGroupNew();
    }
  }, [createGroup.avatar]);
  const getGroups = (filters: any = {}) => {
    // Mock API call to get groups and apply filters if any
    let searchUrl = '/v1.0/groups/search';
    const hasSearchQuery = filters.hasOwnProperty('searchValue');
    const hasDateRange = filters.hasOwnProperty('dateRange');
    if (hasSearchQuery) {
      searchUrl = searchUrl + `?name=${searchValue}`;
    }
    if (hasDateRange) {
      const { start = null, end = null } = filters.dateRange;
      searchUrl = hasSearchQuery
        ? searchUrl + `&since=${start || ''}&till=${end || ''}`
        : searchUrl + `?since=${start || ''}&till=${end || ''}`;
    }
    setLoading(true);
    const url = get_api_url(searchUrl, false, true);
    api_with_method('get', url, null, true)
      .then((response) => {
        setAllGroups(response.data.groups);
        setLoading(false);
      })
      .catch((e) => {
        console.log('error', e);
        setLoading(false);
      });
  };

  const createNewGroup = async () => {
    try {
      setLoading(true);
      let urlOfFile = await uploadImageToS3(createGroup.file);
      setCreateGroup({ ...createGroup, avatar: urlOfFile });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const openCreate = () => setCreateOpen(true);

  const closeCreate = () => {
    setCreateOpen(false);
    setCreateGroup({
      name: '',
      description: '',
      avatar:
        'https://cloudimages.myyogateacher.com/dgerdfai4/image/upload/v1700811519/website/default-group-avatar.png',
      type: 'public',
      members: [],
      file: null
    });
  };

  const openManage = (group: Group) => {
    const { uuid = '' } = group;
    setManageOpen(true);
    const fetchMemberUrl = `/v1.0/groups/${uuid}/members`;
    const url = get_api_url(fetchMemberUrl, false, true);
    api_with_method('get', url, null, true)
      .then((response) => {
        setManageGroup({ group, members: response.data.members });
      })
      .catch((e) => {
        console.log('error', e);
      });
  };

  const closeManage = () => {
    setManageOpen(false);
    setManageGroup(undefined);
  };

  const renderCreate = () => {
    let file: any = null;
    console.log(createGroup, 'role is');
    return (
      <div>
        <h2>Create Group</h2>
        <CustomInput
          label="Enter group name*"
          onChange={(e) => setCreateGroup({ ...createGroup, name: e.target.value })}
          name="name"
          value={createGroup.name}
          style={{ width: '300px' }}
        />
        <CustomInput
          label="Enter group description*"
          onChange={(e) => setCreateGroup({ ...createGroup, description: e.target.value })}
          name="description"
          value={createGroup.description}
          style={{ width: '300px' }}
        />
        <div className="custom-input-label" style={{ width: '300px' }}>
          Group Type:{' '}
        </div>
        <div className="mt-[10px]" style={{ maxWidth: 300 }}>
          <select
            name=""
            onChange={(e) => {
              setCreateGroup({ ...createGroup, type: e.target.value });
            }}
            value={createGroup.type}
          >
            <option value="public">Public</option>
            <option value="private">Private</option>
            {/* <option value="role_teacher">All Teachers</option> */}
            {/* <option value="role_student">All Students</option> */}
          </select>
        </div>
        <div className="custom-input-label mt-[10px]" style={{ width: '300px' }}>
          Upload Group Image*:
        </div>
        <ImageUploader
          onImageUpload={async (file: File) => {
            setCreateGroup({ ...createGroup, file });
          }}
        />
        {!createGroup.type.startsWith('role') ? (
          <>
            <div className="custom-input-label" style={{ width: '300px' }}>
              Add Members:
            </div>
            <div className="flex flex-row mt-[10px]">
              <SearchTeacher
                handleTeacherErrorMSG={() => {}}
                teachersToRemove={manageGroup?.members || []}
                onTeacherUpdate={(_: string, teacher: any) => {
                  if (!createGroup.members.find((mem) => mem.uuid === teacher.uuid)) {
                    setMemberToAdd({
                      uuid: teacher.uuid,
                      name: `${teacher.fname}`,
                      avatar: teacher.avatar || '',
                      role: 'teacher',
                      group_role: 'm'
                    });
                    setAddType('create');
                  }
                }}
                addTeacherBtn
                btnClickAction={(cb) => {
                  if (!createGroup.members.find((mem) => mem.uuid === memberToAdd.uuid)) {
                    setCreateGroup({
                      ...createGroup,
                      members: [
                        ...createGroup.members,
                        {
                          uuid: memberToAdd.uuid,
                          name: `${memberToAdd.name}`,
                          avatar: memberToAdd.avatar || '',
                          role: 'teacher',
                          group_role: 'm'
                        }
                      ]
                    });
                    setMemberToAdd(undefined);
                  }
                  if (cb) {
                    cb();
                  }
                }}
              />
            </div>

            <div className="flex flex-row mt-[10px] mb-[10px]">
              <SearchStudent
                handleStudentErrorMSG={() => {}}
                addStudentBtn
                studentsToRemove={manageGroup?.members || []}
                btnClickAction={(cb) => {
                  if (!createGroup.members.find((mem) => mem.uuid === memberToAdd.uuid)) {
                    setCreateGroup({
                      ...createGroup,
                      members: [
                        ...createGroup.members,
                        {
                          uuid: memberToAdd.uuid,
                          name: memberToAdd.name,
                          avatar: memberToAdd.avatar || '',
                          role: 'student',
                          group_role: 'm'
                        }
                      ]
                    });
                    setMemberToAdd(undefined);
                  }
                  if (cb) {
                    cb();
                  }
                }}
                onStudentUpdate={(a: string, student: any) => {
                  if (!createGroup.members.find((mem) => mem.uuid === student.uuid)) {
                    setMemberToAdd({
                      uuid: student.uuid,
                      name: student.fname,
                      avatar: student.avatar || '',
                      role: 'student',
                      group_role: 'm'
                    });
                    setAddType('create');
                  }
                }}
              />
            </div>
            {createGroup.members.length > 0 && (
              <div
                className="inner-div content-border content-width-full"
                style={{ marginTop: '20px' }}
              >
                {createGroup.members.map((member) => (
                  <div className="md-chips" key={member.uuid}>
                    <label
                      htmlFor={`${member.name}(${member.role})`}
                      id={member.name}
                      className="md-chip md-chip-clickable md-chip-hover inline-flex items-center"
                    >
                      <span>{`${member.name}(${member.role})`} </span>
                      <img
                        src="https://images.myyogateacher.com/cross_filt_pop.svg"
                        alt="close"
                        style={{
                          marginLeft: '10px',
                          height: '12px',
                          width: '12px',
                          position: 'inset'
                        }}
                        onClick={() => {
                          let filteredMembers = createGroup.members;
                          filteredMembers = filteredMembers.filter(
                            (mem) => mem.uuid !== member.uuid
                          );
                          setCreateGroup({ ...createGroup, members: filteredMembers });
                        }}
                      />
                    </label>
                  </div>
                ))}
              </div>
            )}
          </>
        ) : null}
        <Button
          disabled={
            !createGroup.name.trim() || !createGroup.description.trim() || !createGroup.file
          }
          onClick={createNewGroup}
          style={{ marginTop: '20px' }}
        >
          Create
        </Button>
      </div>
    );
  };
  const getDateFilterMapping = (dateStr: string) => {
    let dateRange;

    const now = moment();

    switch (dateStr) {
      case 'TODAY':
        dateRange = {
          start: moment().startOf('day').unix(),
          end: now.unix()
        };
        break;
      case 'YESTERDAY':
        dateRange = {
          start: moment().subtract(1, 'days').startOf('day').unix(),
          end: moment().subtract(1, 'days').endOf('day').unix()
        };
        break;
      case 'THIS WEEK':
        dateRange = {
          start: moment().startOf('week').unix(),
          end: now.unix()
        };
        break;
      case 'LAST WEEK':
        dateRange = {
          start: moment().subtract(1, 'weeks').startOf('week').unix(),
          end: moment().subtract(1, 'weeks').endOf('week').unix()
        };
        break;
      case 'LAST 2 WEEKS':
        dateRange = {
          start: moment().subtract(2, 'weeks').startOf('week').unix(),
          end: moment().subtract(1, 'weeks').endOf('week').unix()
        };
        break;
      case 'LAST 4 WEEKS':
        dateRange = {
          start: moment().subtract(4, 'weeks').startOf('week').unix(),
          end: moment().subtract(1, 'weeks').endOf('week').unix()
        };
        break;
      case 'THIS MONTH':
        dateRange = {
          start: moment().startOf('month').unix(),
          end: now.unix()
        };
        break;
      case 'LAST MONTH':
        dateRange = {
          start: moment().subtract(1, 'months').startOf('month').unix(),
          end: moment().subtract(1, 'months').endOf('month').unix()
        };
        break;
      case 'LAST 30 DAYS':
        dateRange = {
          start: moment().subtract(30, 'days').unix(),
          end: now.unix()
        };
        break;
      case 'Anytime':
        dateRange = {
          start: moment().subtract(10, 'years').unix(),
          end: now.unix()
        };
        break;
      default:
        dateRange = null;
        break;
    }
    return dateRange;
  };
  const changeDateFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const dateFilterType = e.target.value;
    setDateFilterType(dateFilterType);
    if (searchValue.length <= 3) {
      return;
    }
    const dateFilter = getDateFilterMapping(e.target.value);
    let filters = {
      dateRange: dateFilter,
      searchValue
    };
    getGroups(filters);
  };

  const renderGroups = () => {
    return (
      <div className="cus-table">
        <div className="ct-row ct-h">
          <div className="ct-col">Group</div>
          <div className="ct-col">Created At</div>
          <div className="ct-col">Actions</div>
        </div>
        {allGroups.map((group) => (
          <div className="ct-row p-[5px]" key={group.id}>
            <div className="ct-col">
              <div className="flex flex-row items-center gap-[10px]">
                <img
                  className=" w-10 h-10 object-cover rounded-full"
                  src={group.avatar}
                  alt="profile-image"
                />
                <div>{group.name}</div>
              </div>
            </div>
            <div className="ct-col">
              {group.created_at
                ? moment(group.created_at * 1000)
                    .tz(ianaTimezone)
                    .format('ddd, MMM D hh:mm A z')
                : ''}
            </div>
            <div className="ct-col">
              <Button onClick={() => openManage(group)}>Manage</Button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const addMember = async () => {
    if (manageGroup.members.find((mem) => mem.uuid === memberToAdd.uuid)) {
      return;
    }
    await changeRole(memberToAdd, 'join');
  };
  const changeRole = async (member: Member, action: string) => {
    setLoading(true);
    const roleChangeUrl = `/v1.0/groups/${manageGroup.group.uuid}/members/${member.uuid}/${action}`;
    const url = get_api_url(roleChangeUrl, false, true);

    await api_with_method('post', url, {}, true);
    switch (action) {
      case 'remove':
      case 'block': {
        const updatedMembersList = manageGroup.members.filter((mem) => mem.uuid !== member.uuid);
        setManageGroup({ ...manageGroup, members: updatedMembersList });
        setLoading(false);
        break;
      }
      case 'add_admin': {
        const updatedMembersList = manageGroup.members.map((mem) => {
          if (mem.uuid === member.uuid) {
            mem.group_role = 'a';
          }
          return mem;
        });
        setManageGroup({ ...manageGroup, members: updatedMembersList });
        setLoading(false);
        break;
      }
      case 'remove_admin': {
        const updatedMembersList = manageGroup.members.map((mem) => {
          if (mem.uuid === member.uuid) {
            mem.group_role = 'm';
          }
          return mem;
        });
        setManageGroup({ ...manageGroup, members: updatedMembersList });
        setLoading(false);
        break;
      }
      case 'join': {
        const updatedMembersList = [...manageGroup.members];
        updatedMembersList.push(member);
        setManageGroup({ ...manageGroup, members: updatedMembersList });
        setMemberToAdd(undefined);
        setLoading(false);
        break;
      }
    }
  };

  const uploadImageToS3 = async (file: File): Promise<string> => {
    try {
      let result = await S3Handler.shared.uploadFileToS3(file, S3ACL.Public);
      return result.url.split('?')[0].trim();
    } catch (error) {
      console.log(error);
    }
  };
  const renderManageModal = () => {
    if (!manageGroup) return null;
    return (
      <div>
        <h4>Image:</h4>

        <ImageUploader
          previewUrl={manageGroup.group.avatar}
          onImageUpload={async (file: File) => {
            setManageGroup({ ...manageGroup, group: { ...manageGroup.group, file } });
          }}
        />
        <Button
          disabled={!manageGroup.group.file}
          onClick={async () => {
            setLoading(true);
            try {
              let uploadImageUrl = await uploadImageToS3(manageGroup.group.file);

              const url = get_api_url(`/v1.0/groups/${manageGroup.group.uuid}`, false, true);
              await api_with_method('post', url, { avatar: uploadImageUrl }, true);
              setLoading(false);
            } catch (error) {
              setLoading(false);
            }
          }}
        >
          Update Image
        </Button>
        <div className="flex items-start flex-col mt-5">
          <h4>Group Name:</h4>
          <div className="flex items-start flex-row justify-center">
            <input
              className="sl-input"
              placeholder="Enter new group name"
              name="search_value"
              value={manageGroup.group.name}
              id="edit_group_name"
              onChange={(e) =>
                setManageGroup({
                  ...manageGroup,
                  group: { ...manageGroup.group, name: e.target.value }
                })
              }
            />
            <div
              style={{
                position: 'relative',
                top: -3
              }}
            >
              <Button
                disabled={!manageGroup.group.name.trim()}
                onClick={() => {
                  const name: string = manageGroup.group.name;
                  if (name.length > 3) {
                    setLoading(true);
                    const url = get_api_url(`/v1.0/groups/${manageGroup.group.uuid}`, false, true);
                    try {
                      api_with_method('post', url, { name }, true);
                      let currentGroups = [...allGroups];
                      let indexOfChangedGroup = currentGroups.findIndex(
                        (grp) => grp.uuid === manageGroup.group.uuid
                      );
                      if (indexOfChangedGroup !== -1) {
                        currentGroups[indexOfChangedGroup] = {
                          ...currentGroups[indexOfChangedGroup],
                          group: { ...currentGroups[indexOfChangedGroup].group, name }
                        };
                        setAllGroups(currentGroups);
                        setLoading(false);
                      }
                    } catch (error) {
                      console.log(error);
                      setLoading(false);
                    }
                  }
                }}
                style={{ marginLeft: '20px' }}
              >
                Update name
              </Button>
            </div>
          </div>
        </div>
        <div className="flex flex-row mt-[10px]">
          <SearchTeacher
            handleTeacherErrorMSG={() => {}}
            addTeacherBtn
            teachersToRemove={manageGroup?.members || []}
            btnClickAction={(cb) => {
              addMember();
              if (cb) {
                cb();
              }
            }}
            onTeacherUpdate={(_: string, teacher: any) => {
              setMemberToAdd({
                uuid: teacher.uuid,
                name: teacher.fname,
                avatar: teacher.avatar || '',
                role: 'teacher',
                group_role: 'm'
              });
            }}
          />
        </div>

        <div className="flex flex-row mt-[10px] mb-[10px]">
          <SearchStudent
            addStudentBtn
            studentsToRemove={manageGroup?.members || []}
            btnClickAction={(cb) => {
              addMember();
              if (cb) {
                cb();
              }
            }}
            handleStudentErrorMSG={() => {}}
            onStudentUpdate={(a: string, student: any) => {
              setMemberToAdd({
                uuid: student.uuid,
                name: student.fname,
                avatar: student.avatar || '',
                role: 'student',
                group_role: 'm'
              });
            }}
          />
        </div>
        <h4>Group Members:</h4>
        <div style={{ maxHeight: '200px', overflowY: 'scroll', marginTop: '10px' }}>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {manageGroup.members.map((member) => {
                const isAdmin = member.group_role === 'a';
                return (
                  <tr key={member.uuid}>
                    <td>
                      <div className="ct-col ct-hf ct-la ct-bw">
                        <a
                          className="link-no-dec"
                          href={`/studentprofile?id=${member.uuid}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="profile-img-name">{member.name} </div>
                          <div className="capitalize" style={{ fontSize: '10px' }}>
                            ({member.role})
                          </div>
                        </a>
                      </div>
                    </td>
                    <td> {member.group_role === 'a' ? 'Admin' : 'Member'}</td>
                    <td>
                      <Button
                        onClick={() => changeRole(member, isAdmin ? 'remove_admin' : 'add_admin')}
                      >
                        {`${isAdmin ? 'Make Member' : 'Make Admin'}`}
                      </Button>
                      <Button onClick={() => changeRole(member, 'remove')}>Remove</Button>
                      <Button onClick={() => changeRole(member, 'block')}>Block</Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  const crossSvg = () => {
    return (
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: 15,
          height: 15,
          position: 'absolute',
          left: '96%'
        }}
      >
        <line x1="10" y1="10" x2="90" y2="90" stroke="black" stroke-width="10"></line>
        <line x1="90" y1="10" x2="10" y2="90" stroke="black" stroke-width="10"></line>
      </svg>
    );
  };
  return (
    <CommonHeader loading={studentSearchStatus === 'loading' || loading} title="Chat Groups">
      <div style={{ minHeight: 400 }}>
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <CustomSingleSelect
              label="Select a date filter"
              name="date_filter"
              style={{ width: '250px' }}
              options={datesForSearch}
              onChange={changeDateFilter}
              value={dateFilterType}
            />
          </div>
          <div className="flex items-center">
            <input
              className="sl-input"
              placeholder="Enter group name to search"
              name="search_value"
              value={searchValue}
              onChange={handleSearchInput}
            />
            <Button
              onClick={() => {
                searchValue.trim().length >= 3
                  ? getGroups({
                      searchValue,
                      dateRange: getDateFilterMapping(dateFilterType)
                    })
                  : null;
              }}
              disabled={searchValue.length < 3}
              style={{ marginLeft: '10px' }}
            >
              Search
            </Button>
          </div>

          <Button onClick={openCreate} style={{ marginLeft: '10px' }}>
            + Create Group
          </Button>
        </div>
        <h2>*Search the groups starting with 3 characters</h2>
        {allGroups.length > 0 && renderGroups()}
        <CustomModal show={createOpen} close={closeCreate}>
          <div className="cursor-pointer" onClick={closeCreate}>
            {crossSvg()}
          </div>

          {renderCreate()}
        </CustomModal>
        <CustomModal show={manageOpen} close={closeManage}>
          <div className="cursor-pointer" onClick={closeManage}>
            {crossSvg()}
          </div>
          {renderManageModal()}
        </CustomModal>
      </div>
    </CommonHeader>
  );
};

export default GroupChats;
